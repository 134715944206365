table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important; }
  table.dataTable td,
  table.dataTable th {
    -webkit-box-sizing: content-box;
    box-sizing: content-box; }
    table.dataTable td.dataTables_empty,
    table.dataTable th.dataTables_empty {
      text-align: center; }
  table.dataTable.nowrap th,
  table.dataTable.nowrap td {
    white-space: nowrap; }

div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap; }

div.dataTables_wrapper div.dataTables_length select {
  width: 75px;
  display: inline-block; }

div.dataTables_wrapper div.dataTables_filter {
  text-align: right; }
  div.dataTables_wrapper div.dataTables_filter label {
    font-weight: normal;
    white-space: nowrap;
    text-align: left; }
  div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em;
    display: inline-block;
    width: auto; }

div.dataTables_wrapper div.dataTables_info {
  padding-top: 8px;
  white-space: nowrap; }

div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right; }
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    white-space: nowrap; }

div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0; }

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 30px; }

table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none; }

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative; }
  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:after {
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: block;
    font-family: 'Glyphicons Halflings';
    opacity: 0.5; }

table.dataTable thead .sorting:after {
  opacity: 0.2;
  content: "\e150";
  /* sort */ }

table.dataTable thead .sorting_asc:after {
  content: "\e155";
  /* sort-by-attributes */ }

table.dataTable thead .sorting_desc:after {
  content: "\e156";
  /* sort-by-attributes-alt */ }

table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  color: #eee; }

div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important; }

div.dataTables_scrollBody > table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important; }
  div.dataTables_scrollBody > table > thead .sorting:after,
  div.dataTables_scrollBody > table > thead .sorting_asc:after,
  div.dataTables_scrollBody > table > thead .sorting_desc:after {
    display: none; }
  div.dataTables_scrollBody > table > tbody > tr:first-child > th,
  div.dataTables_scrollBody > table > tbody > tr:first-child > td {
    border-top: none; }

div.dataTables_scrollFoot > .dataTables_scrollFootInner {
  box-sizing: content-box; }
  div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
    margin-top: 0 !important;
    border-top: none; }

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: center; } }

table.dataTable.table-condensed > thead > tr > th {
  padding-right: 20px; }

table.dataTable.table-condensed .sorting:after,
table.dataTable.table-condensed .sorting_asc:after,
table.dataTable.table-condensed .sorting_desc:after {
  top: 6px;
  right: 6px; }

table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  border-left-width: 0; }
  table.table-bordered.dataTable th:last-child, table.table-bordered.dataTable th:last-child,
  table.table-bordered.dataTable td:last-child,
  table.table-bordered.dataTable td:last-child {
    border-right-width: 0; }

table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
  border-bottom-width: 0; }

div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0; }

div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0; }
  div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child {
    padding-left: 0; }
  div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:last-child {
    padding-right: 0; }

table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0; }
  table.dataTable td,
  table.dataTable th {
    -webkit-box-sizing: content-box;
    box-sizing: content-box; }
    table.dataTable td.dataTables_empty,
    table.dataTable th.dataTables_empty {
      text-align: center; }
  table.dataTable.nowrap th,
  table.dataTable.nowrap td {
    white-space: nowrap; }

div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap; }

div.dataTables_wrapper div.dataTables_length select {
  width: 75px;
  display: inline-block; }

div.dataTables_wrapper div.dataTables_filter {
  text-align: right; }
  div.dataTables_wrapper div.dataTables_filter label {
    font-weight: normal;
    white-space: nowrap;
    text-align: left; }
  div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em;
    display: inline-block;
    width: auto; }

div.dataTables_wrapper div.dataTables_info {
  padding-top: 0.85em;
  white-space: nowrap; }

div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right; }
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    white-space: nowrap;
    justify-content: flex-end; }

div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0; }

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 30px; }

table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none; }

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative; }
  table.dataTable thead .sorting:before, table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:before,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_asc_disabled:before,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:before,
  table.dataTable thead .sorting_desc_disabled:after {
    position: absolute;
    bottom: 0.9em;
    display: block;
    opacity: 0.3; }
  table.dataTable thead .sorting:before,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_desc:before,
  table.dataTable thead .sorting_asc_disabled:before,
  table.dataTable thead .sorting_desc_disabled:before {
    right: 1em;
    content: "\2191"; }
  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:after {
    right: 0.5em;
    content: "\2193"; }

table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
  opacity: 1; }

table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  opacity: 0; }

div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important; }

div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important; }
  div.dataTables_scrollBody table thead .sorting:before,
  div.dataTables_scrollBody table thead .sorting_asc:before,
  div.dataTables_scrollBody table thead .sorting_desc:before,
  div.dataTables_scrollBody table thead .sorting:after,
  div.dataTables_scrollBody table thead .sorting_asc:after,
  div.dataTables_scrollBody table thead .sorting_desc:after {
    display: none; }
  div.dataTables_scrollBody table tbody tr:first-child th,
  div.dataTables_scrollBody table tbody tr:first-child td {
    border-top: none; }

div.dataTables_scrollFoot > .dataTables_scrollFootInner {
  box-sizing: content-box; }
  div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
    margin-top: 0 !important;
    border-top: none; }

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: center; }
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    justify-content: center; } }

table.dataTable.table-sm > thead > tr > th {
  padding-right: 20px; }

table.dataTable.table-sm .sorting:before,
table.dataTable.table-sm .sorting_asc:before,
table.dataTable.table-sm .sorting_desc:before {
  top: 5px;
  right: 0.85em; }

table.dataTable.table-sm .sorting:after,
table.dataTable.table-sm .sorting_asc:after,
table.dataTable.table-sm .sorting_desc:after {
  top: 5px; }

table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  border-left-width: 0; }
  table.table-bordered.dataTable th:last-child, table.table-bordered.dataTable th:last-child,
  table.table-bordered.dataTable td:last-child,
  table.table-bordered.dataTable td:last-child {
    border-right-width: 0; }

table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
  border-bottom-width: 0; }

div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0; }

div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0; }
  div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child {
    padding-left: 0; }
  div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:last-child {
    padding-right: 0; }

table.dataTable {
  clear: both;
  margin: 0.5em 0 !important;
  max-width: none !important;
  width: 100%; }
  table.dataTable td,
  table.dataTable th {
    -webkit-box-sizing: content-box;
    box-sizing: content-box; }
    table.dataTable td.dataTables_empty,
    table.dataTable th.dataTables_empty {
      text-align: center; }
  table.dataTable.nowrap th, table.dataTable.nowrap td {
    white-space: nowrap; }

div.dataTables_wrapper {
  position: relative;
  font-family: 'Open Sans', sans-serif; }
  div.dataTables_wrapper div.dataTables_length label {
    float: left;
    text-align: left;
    margin-bottom: 0; }
  div.dataTables_wrapper div.dataTables_length select {
    width: 75px;
    margin-bottom: 0; }
  div.dataTables_wrapper div.dataTables_filter label {
    float: right;
    margin-bottom: 0; }
  div.dataTables_wrapper div.dataTables_filter input {
    display: inline-block !important;
    width: auto !important;
    margin-bottom: 0;
    margin-left: 0.5em; }
  div.dataTables_wrapper div.dataTables_info {
    padding-top: 2px; }
  div.dataTables_wrapper div.dataTables_paginate {
    float: right;
    margin: 0; }
  div.dataTables_wrapper div.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding: 1rem 0; }

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 1.5rem; }

table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none; }

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer; }

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  background-repeat: no-repeat;
  background-position: center right; }

table.dataTable thead .sorting {
  background-image: url("../images/sort_both.png"); }

table.dataTable thead .sorting_asc {
  background-image: url("../images/sort_asc.png"); }

table.dataTable thead .sorting_desc {
  background-image: url("../images/sort_desc.png"); }

table.dataTable thead .sorting_asc_disabled {
  background-image: url("../images/sort_asc_disabled.png"); }

table.dataTable thead .sorting_desc_disabled {
  background-image: url("../images/sort_desc_disabled.png"); }

div.dataTables_scrollHead table {
  margin-bottom: 0 !important; }

div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important; }
  div.dataTables_scrollBody table tbody tr:first-child th,
  div.dataTables_scrollBody table tbody tr:first-child td {
    border-top: none; }

div.dataTables_scrollFoot table {
  margin-top: 0 !important;
  border-top: none; }

/*
 * Table styles
 */
table.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
  /*
	 * Header and footer styles
	 */
  /*
	 * Body styles
	 */ }
  table.dataTable thead th,
  table.dataTable tfoot th {
    font-weight: bold; }
  table.dataTable thead th,
  table.dataTable thead td {
    padding: 10px 18px; }
    table.dataTable thead th:active,
    table.dataTable thead td:active {
      outline: none; }
  table.dataTable tfoot th,
  table.dataTable tfoot td {
    padding: 10px 18px 6px 18px; }
  table.dataTable tbody tr {
    background-color: #ffffff; }
    table.dataTable tbody tr.selected {
      background-color: #B0BED9; }
  table.dataTable tbody th,
  table.dataTable tbody td {
    padding: 8px 10px; }
  table.dataTable.row-border tbody th, table.dataTable.row-border tbody td,
  table.dataTable.display tbody th,
  table.dataTable.display tbody td {
    border-top: 1px solid #ddd; }
  table.dataTable.row-border tbody tr:first-child th,
  table.dataTable.row-border tbody tr:first-child td,
  table.dataTable.display tbody tr:first-child th,
  table.dataTable.display tbody tr:first-child td {
    border-top: none; }
  table.dataTable.cell-border tbody th, table.dataTable.cell-border tbody td {
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd; }
  table.dataTable.cell-border tbody tr th:first-child,
  table.dataTable.cell-border tbody tr td:first-child {
    border-left: 1px solid #ddd; }
  table.dataTable.cell-border tbody tr:first-child th,
  table.dataTable.cell-border tbody tr:first-child td {
    border-top: none; }
  table.dataTable.stripe tbody tr.odd,
  table.dataTable.display tbody tr.odd {
    background-color: #f9f9f9; }
    table.dataTable.stripe tbody tr.odd.selected,
    table.dataTable.display tbody tr.odd.selected {
      background-color: #acbad4; }
  table.dataTable.hover tbody tr:hover,
  table.dataTable.display tbody tr:hover {
    background-color: #f6f6f6; }
    table.dataTable.hover tbody tr:hover.selected,
    table.dataTable.display tbody tr:hover.selected {
      background-color: #aab7d1; }
  table.dataTable.order-column tbody tr > .sorting_1,
  table.dataTable.order-column tbody tr > .sorting_2,
  table.dataTable.order-column tbody tr > .sorting_3, table.dataTable.display tbody tr > .sorting_1,
  table.dataTable.display tbody tr > .sorting_2,
  table.dataTable.display tbody tr > .sorting_3 {
    background-color: #fafafa; }
  table.dataTable.order-column tbody tr.selected > .sorting_1,
  table.dataTable.order-column tbody tr.selected > .sorting_2,
  table.dataTable.order-column tbody tr.selected > .sorting_3, table.dataTable.display tbody tr.selected > .sorting_1,
  table.dataTable.display tbody tr.selected > .sorting_2,
  table.dataTable.display tbody tr.selected > .sorting_3 {
    background-color: #acbad5; }
  table.dataTable.display tbody tr.odd > .sorting_1,
  table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
    background-color: #f1f1f1; }
  table.dataTable.display tbody tr.odd > .sorting_2,
  table.dataTable.order-column.stripe tbody tr.odd > .sorting_2 {
    background-color: #f3f3f3; }
  table.dataTable.display tbody tr.odd > .sorting_3,
  table.dataTable.order-column.stripe tbody tr.odd > .sorting_3 {
    background-color: whitesmoke; }
  table.dataTable.display tbody tr.odd.selected > .sorting_1,
  table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
    background-color: #a6b4cd; }
  table.dataTable.display tbody tr.odd.selected > .sorting_2,
  table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2 {
    background-color: #a8b5cf; }
  table.dataTable.display tbody tr.odd.selected > .sorting_3,
  table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3 {
    background-color: #a9b7d1; }
  table.dataTable.display tbody tr.even > .sorting_1,
  table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
    background-color: #fafafa; }
  table.dataTable.display tbody tr.even > .sorting_2,
  table.dataTable.order-column.stripe tbody tr.even > .sorting_2 {
    background-color: #fcfcfc; }
  table.dataTable.display tbody tr.even > .sorting_3,
  table.dataTable.order-column.stripe tbody tr.even > .sorting_3 {
    background-color: #fefefe; }
  table.dataTable.display tbody tr.even.selected > .sorting_1,
  table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 {
    background-color: #acbad5; }
  table.dataTable.display tbody tr.even.selected > .sorting_2,
  table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2 {
    background-color: #aebcd6; }
  table.dataTable.display tbody tr.even.selected > .sorting_3,
  table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3 {
    background-color: #afbdd8; }
  table.dataTable.display tbody tr:hover > .sorting_1,
  table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
    background-color: #eaeaea; }
  table.dataTable.display tbody tr:hover > .sorting_2,
  table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
    background-color: #ececec; }
  table.dataTable.display tbody tr:hover > .sorting_3,
  table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
    background-color: #efefef; }
  table.dataTable.display tbody tr:hover.selected > .sorting_1,
  table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
    background-color: #a2aec7; }
  table.dataTable.display tbody tr:hover.selected > .sorting_2,
  table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
    background-color: #a3b0c9; }
  table.dataTable.display tbody tr:hover.selected > .sorting_3,
  table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
    background-color: #a5b2cb; }
  table.dataTable.no-footer {
    border-bottom: 1px solid #111; }
  table.dataTable.nowrap th, table.dataTable.nowrap td {
    white-space: nowrap; }
  table.dataTable.compact thead th,
  table.dataTable.compact thead td {
    padding: 4px 17px 4px 4px; }
  table.dataTable.compact tfoot th,
  table.dataTable.compact tfoot td {
    padding: 4px; }
  table.dataTable.compact tbody th,
  table.dataTable.compact tbody td {
    padding: 4px; }
  table.dataTable th.dt-left,
  table.dataTable td.dt-left {
    text-align: left; }
  table.dataTable th.dt-center,
  table.dataTable td.dt-center,
  table.dataTable td.dataTables_empty {
    text-align: center; }
  table.dataTable th.dt-right,
  table.dataTable td.dt-right {
    text-align: right; }
  table.dataTable th.dt-justify,
  table.dataTable td.dt-justify {
    text-align: justify; }
  table.dataTable th.dt-nowrap,
  table.dataTable td.dt-nowrap {
    white-space: nowrap; }
  table.dataTable thead th.dt-head-left,
  table.dataTable thead td.dt-head-left,
  table.dataTable tfoot th.dt-head-left,
  table.dataTable tfoot td.dt-head-left {
    text-align: left; }
  table.dataTable thead th.dt-head-center,
  table.dataTable thead td.dt-head-center,
  table.dataTable tfoot th.dt-head-center,
  table.dataTable tfoot td.dt-head-center {
    text-align: center; }
  table.dataTable thead th.dt-head-right,
  table.dataTable thead td.dt-head-right,
  table.dataTable tfoot th.dt-head-right,
  table.dataTable tfoot td.dt-head-right {
    text-align: right; }
  table.dataTable thead th.dt-head-justify,
  table.dataTable thead td.dt-head-justify,
  table.dataTable tfoot th.dt-head-justify,
  table.dataTable tfoot td.dt-head-justify {
    text-align: justify; }
  table.dataTable thead th.dt-head-nowrap,
  table.dataTable thead td.dt-head-nowrap,
  table.dataTable tfoot th.dt-head-nowrap,
  table.dataTable tfoot td.dt-head-nowrap {
    white-space: nowrap; }
  table.dataTable tbody th.dt-body-left,
  table.dataTable tbody td.dt-body-left {
    text-align: left; }
  table.dataTable tbody th.dt-body-center,
  table.dataTable tbody td.dt-body-center {
    text-align: center; }
  table.dataTable tbody th.dt-body-right,
  table.dataTable tbody td.dt-body-right {
    text-align: right; }
  table.dataTable tbody th.dt-body-justify,
  table.dataTable tbody td.dt-body-justify {
    text-align: justify; }
  table.dataTable tbody th.dt-body-nowrap,
  table.dataTable tbody td.dt-body-nowrap {
    white-space: nowrap; }

table.dataTable,
table.dataTable th,
table.dataTable td {
  box-sizing: content-box; }

/*
 * Control feature layout
 */
.dataTables_wrapper {
  position: relative;
  clear: both;
  *zoom: 1;
  zoom: 1; }
  .dataTables_wrapper .dataTables_length {
    float: left; }
  .dataTables_wrapper .dataTables_filter {
    float: right;
    text-align: right; }
    .dataTables_wrapper .dataTables_filter input {
      margin-left: 0.5em; }
  .dataTables_wrapper .dataTables_info {
    clear: both;
    float: left;
    padding-top: 0.755em; }
  .dataTables_wrapper .dataTables_paginate {
    float: right;
    text-align: right;
    padding-top: 0.25em; }
    .dataTables_wrapper .dataTables_paginate .paginate_button {
      box-sizing: border-box;
      display: inline-block;
      min-width: 1.5em;
      padding: 0.5em 1em;
      margin-left: 2px;
      text-align: center;
      text-decoration: none !important;
      cursor: pointer;
      *cursor: hand;
      color: #333 !important;
      border: 1px solid transparent;
      border-radius: 2px; }
      .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
        color: #333 !important;
        border: 1px solid #979797;
        background-color: white;
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(100%, #dcdcdc));
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, white 0%, #dcdcdc 100%);
        /* Chrome10+,Safari5.1+ */
        background: -moz-linear-gradient(top, white 0%, #dcdcdc 100%);
        /* FF3.6+ */
        background: -ms-linear-gradient(top, white 0%, #dcdcdc 100%);
        /* IE10+ */
        background: -o-linear-gradient(top, white 0%, #dcdcdc 100%);
        /* Opera 11.10+ */
        background: linear-gradient(to bottom, white 0%, #dcdcdc 100%);
        /* W3C */ }
      .dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
        cursor: default;
        color: #666 !important;
        border: 1px solid transparent;
        background: transparent;
        box-shadow: none; }
      .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
        color: white !important;
        border: 1px solid #111;
        background-color: #585858;
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
        /* Chrome10+,Safari5.1+ */
        background: -moz-linear-gradient(top, #585858 0%, #111 100%);
        /* FF3.6+ */
        background: -ms-linear-gradient(top, #585858 0%, #111 100%);
        /* IE10+ */
        background: -o-linear-gradient(top, #585858 0%, #111 100%);
        /* Opera 11.10+ */
        background: linear-gradient(to bottom, #585858 0%, #111 100%);
        /* W3C */ }
      .dataTables_wrapper .dataTables_paginate .paginate_button:active {
        outline: none;
        background-color: #2b2b2b;
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2b2b2b), color-stop(100%, #0c0c0c));
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
        /* Chrome10+,Safari5.1+ */
        background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
        /* FF3.6+ */
        background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
        /* IE10+ */
        background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
        /* Opera 11.10+ */
        background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
        /* W3C */
        box-shadow: inset 0 0 3px #111; }
    .dataTables_wrapper .dataTables_paginate .ellipsis {
      padding: 0 1em; }
  .dataTables_wrapper .dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 40px;
    margin-left: -50%;
    margin-top: -25px;
    padding-top: 20px;
    text-align: center;
    font-size: 1.2em;
    background-color: white;
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%); }
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter,
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_processing,
  .dataTables_wrapper .dataTables_paginate {
    color: #333; }
  .dataTables_wrapper .dataTables_scroll {
    clear: both; }
    .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
      *margin-top: -1px;
      -webkit-overflow-scrolling: touch; }
      .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td {
        vertical-align: middle; }
      .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th > div.dataTables_sizing,
      .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th > div.dataTables_sizing,
      .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td > div.dataTables_sizing {
        height: 0;
        overflow: hidden;
        margin: 0 !important;
        padding: 0 !important; }
  .dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: 1px solid #111; }
  .dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable,
  .dataTables_wrapper.no-footer div.dataTables_scrollBody > table {
    border-bottom: none; }
  .dataTables_wrapper:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0; }

@media screen and (max-width: 767px) {
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: center; }
  .dataTables_wrapper .dataTables_paginate {
    margin-top: 0.5em; } }

@media screen and (max-width: 640px) {
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter {
    float: none;
    text-align: center; }
  .dataTables_wrapper .dataTables_filter {
    margin-top: 0.5em; } }

table.dataTable thead th div.DataTables_sort_wrapper {
  position: relative; }
  table.dataTable thead th div.DataTables_sort_wrapper span {
    position: absolute;
    top: 50%;
    margin-top: -8px;
    right: -18px; }

table.dataTable thead th.ui-state-default,
table.dataTable tfoot th.ui-state-default {
  border-left-width: 0; }
  table.dataTable thead th.ui-state-default:first-child,
  table.dataTable tfoot th.ui-state-default:first-child {
    border-left-width: 1px; }

/*
 * Control feature layout
 */
.dataTables_wrapper .dataTables_paginate .fg-button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  *cursor: hand;
  border: 1px solid transparent; }
  .dataTables_wrapper .dataTables_paginate .fg-button:active {
    outline: none; }

.dataTables_wrapper .dataTables_paginate .fg-button:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px; }

.dataTables_wrapper .dataTables_paginate .fg-button:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; }

.dataTables_wrapper .ui-widget-header {
  font-weight: normal; }

.dataTables_wrapper .ui-toolbar {
  padding: 8px; }

.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: none; }

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: inherit; }

div.dataTables_wrapper div.dataTables_filter {
  text-align: right; }
  div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em; }

div.dataTables_wrapper div.dataTables_info {
  padding-top: 10px;
  white-space: nowrap; }

div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  text-align: center; }

div.dataTables_wrapper div.dataTables_paginate {
  text-align: right; }

div.dataTables_wrapper div.mdl-grid.dt-table {
  padding-top: 0;
  padding-bottom: 0; }
  div.dataTables_wrapper div.mdl-grid.dt-table > div.mdl-cell {
    margin-top: 0;
    margin-bottom: 0; }

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 30px; }

table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none; }

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative; }
  table.dataTable thead .sorting:before, table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:before,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_asc_disabled:before,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:before,
  table.dataTable thead .sorting_desc_disabled:after {
    position: absolute;
    bottom: 11px;
    display: block;
    opacity: 0.3;
    font-size: 1.3em; }
  table.dataTable thead .sorting:before,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_desc:before,
  table.dataTable thead .sorting_asc_disabled:before,
  table.dataTable thead .sorting_desc_disabled:before {
    right: 1em;
    content: "\2191"; }
  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:after {
    right: 0.5em;
    content: "\2193"; }

table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
  opacity: 1; }

table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  opacity: 0; }

/*
 * Styling for DataTables with Semantic UI
 */
table.dataTable.table {
  margin: 0; }
  table.dataTable.table thead th,
  table.dataTable.table thead td {
    position: relative; }
    table.dataTable.table thead th.sorting, table.dataTable.table thead th.sorting_asc, table.dataTable.table thead th.sorting_desc,
    table.dataTable.table thead td.sorting,
    table.dataTable.table thead td.sorting_asc,
    table.dataTable.table thead td.sorting_desc {
      padding-right: 20px; }
      table.dataTable.table thead th.sorting:after, table.dataTable.table thead th.sorting_asc:after, table.dataTable.table thead th.sorting_desc:after,
      table.dataTable.table thead td.sorting:after,
      table.dataTable.table thead td.sorting_asc:after,
      table.dataTable.table thead td.sorting_desc:after {
        position: absolute;
        top: 12px;
        right: 8px;
        display: block;
        font-family: Icons; }
    table.dataTable.table thead th.sorting:after,
    table.dataTable.table thead td.sorting:after {
      content: "\f0dc";
      color: #ddd;
      font-size: 0.8em; }
  table.dataTable.table td,
  table.dataTable.table th {
    -webkit-box-sizing: content-box;
    box-sizing: content-box; }
    table.dataTable.table td.dataTables_empty,
    table.dataTable.table th.dataTables_empty {
      text-align: center; }
  table.dataTable.table.nowrap th,
  table.dataTable.table.nowrap td {
    white-space: nowrap; }

div.dataTables_wrapper div.dataTables_length select {
  vertical-align: middle;
  min-height: 2.7142em; }

div.dataTables_wrapper div.dataTables_length .ui.selection.dropdown {
  min-width: 0; }

div.dataTables_wrapper div.dataTables_filter span.input {
  margin-left: 0.5em; }

div.dataTables_wrapper div.dataTables_info {
  padding-top: 13px;
  white-space: nowrap; }

div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  text-align: center; }

div.dataTables_wrapper div.row.dt-table {
  padding: 0; }

div.dataTables_wrapper div.dataTables_scrollHead table.dataTable {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: none; }

div.dataTables_wrapper div.dataTables_scrollBody thead .sorting:after,
div.dataTables_wrapper div.dataTables_scrollBody thead .sorting_asc:after,
div.dataTables_wrapper div.dataTables_scrollBody thead .sorting_desc:after {
  display: none; }

div.dataTables_wrapper div.dataTables_scrollBody table.dataTable {
  border-radius: 0;
  border-top: none;
  border-bottom-width: 0; }

div.dataTables_wrapper div.dataTables_scrollBody table.dataTable.no-footer {
  border-bottom-width: 1px; }

div.dataTables_wrapper div.dataTables_scrollFoot table.dataTable {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top: none; }

table.dataTable {
  clear: both; }
  table.dataTable td,
  table.dataTable th {
    -webkit-box-sizing: content-box;
    box-sizing: content-box; }
    table.dataTable td.dataTables_empty,
    table.dataTable th.dataTables_empty {
      text-align: center; }
  table.dataTable.nowrap th,
  table.dataTable.nowrap td {
    white-space: nowrap; }

div.dataTables_wrapper div.row.uk-grid.dt-merge-grid {
  margin-top: 5px; }

div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap; }

div.dataTables_wrapper div.dataTables_length select {
  width: 75px;
  display: inline-block; }

div.dataTables_wrapper div.dataTables_filter {
  text-align: right; }
  div.dataTables_wrapper div.dataTables_filter label {
    font-weight: normal;
    white-space: nowrap;
    text-align: left; }
  div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em;
    display: inline-block;
    width: auto; }

div.dataTables_wrapper div.dataTables_info {
  padding-top: 8px;
  white-space: nowrap; }

div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right; }
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    white-space: nowrap; }

div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0; }

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 30px; }

table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none; }

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative; }
  table.dataTable thead .sorting:before, table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:before,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_asc_disabled:before,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:before,
  table.dataTable thead .sorting_desc_disabled:after {
    position: absolute;
    bottom: 1.2em;
    display: block;
    opacity: 0.3; }
  table.dataTable thead .sorting:before,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_desc:before,
  table.dataTable thead .sorting_asc_disabled:before,
  table.dataTable thead .sorting_desc_disabled:before {
    right: 1em;
    content: "\2191"; }
  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:after {
    right: 0.5em;
    content: "\2193"; }

table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
  opacity: 1; }

table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  opacity: 0; }

div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important; }

div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important; }
  div.dataTables_scrollBody table thead .sorting:after,
  div.dataTables_scrollBody table thead .sorting_asc:after,
  div.dataTables_scrollBody table thead .sorting_desc:after {
    display: none; }
  div.dataTables_scrollBody table tbody tr:first-child th,
  div.dataTables_scrollBody table tbody tr:first-child td {
    border-top: none; }

div.dataTables_scrollFoot table {
  margin-top: 0 !important;
  border-top: none; }

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: center; } }

table.dataTable.uk-table-condensed > thead > tr > th {
  padding-right: 20px; }

table.dataTable.uk-table-condensed .sorting:after,
table.dataTable.uk-table-condensed .sorting_asc:after,
table.dataTable.uk-table-condensed .sorting_desc:after {
  top: 6px;
  right: 6px; }

/*
 * Table styles
 */
table.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
  /*
	 * Header and footer styles
	 */
  /*
	 * Body styles
	 */ }
  table.dataTable thead th,
  table.dataTable tfoot th {
    font-weight: bold; }
  table.dataTable thead th,
  table.dataTable thead td {
    padding: 10px 18px; }
    table.dataTable thead th:active,
    table.dataTable thead td:active {
      outline: none; }
  table.dataTable tfoot th,
  table.dataTable tfoot td {
    padding: 10px 18px 6px 18px; }
  table.dataTable tbody tr {
    background-color: #ffffff; }
    table.dataTable tbody tr.selected {
      background-color: #B0BED9; }
  table.dataTable tbody th,
  table.dataTable tbody td {
    padding: 8px 10px; }
  table.dataTable.row-border tbody th, table.dataTable.row-border tbody td,
  table.dataTable.display tbody th,
  table.dataTable.display tbody td {
    border-top: 1px solid #ddd; }
  table.dataTable.row-border tbody tr:first-child th,
  table.dataTable.row-border tbody tr:first-child td,
  table.dataTable.display tbody tr:first-child th,
  table.dataTable.display tbody tr:first-child td {
    border-top: none; }
  table.dataTable.cell-border tbody th, table.dataTable.cell-border tbody td {
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd; }
  table.dataTable.cell-border tbody tr th:first-child,
  table.dataTable.cell-border tbody tr td:first-child {
    border-left: 1px solid #ddd; }
  table.dataTable.cell-border tbody tr:first-child th,
  table.dataTable.cell-border tbody tr:first-child td {
    border-top: none; }
  table.dataTable.stripe tbody tr.odd,
  table.dataTable.display tbody tr.odd {
    background-color: #f9f9f9; }
    table.dataTable.stripe tbody tr.odd.selected,
    table.dataTable.display tbody tr.odd.selected {
      background-color: #acbad4; }
  table.dataTable.hover tbody tr:hover,
  table.dataTable.display tbody tr:hover {
    background-color: #f6f6f6; }
    table.dataTable.hover tbody tr:hover.selected,
    table.dataTable.display tbody tr:hover.selected {
      background-color: #aab7d1; }
  table.dataTable.order-column tbody tr > .sorting_1,
  table.dataTable.order-column tbody tr > .sorting_2,
  table.dataTable.order-column tbody tr > .sorting_3, table.dataTable.display tbody tr > .sorting_1,
  table.dataTable.display tbody tr > .sorting_2,
  table.dataTable.display tbody tr > .sorting_3 {
    background-color: #fafafa; }
  table.dataTable.order-column tbody tr.selected > .sorting_1,
  table.dataTable.order-column tbody tr.selected > .sorting_2,
  table.dataTable.order-column tbody tr.selected > .sorting_3, table.dataTable.display tbody tr.selected > .sorting_1,
  table.dataTable.display tbody tr.selected > .sorting_2,
  table.dataTable.display tbody tr.selected > .sorting_3 {
    background-color: #acbad5; }
  table.dataTable.display tbody tr.odd > .sorting_1,
  table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
    background-color: #f1f1f1; }
  table.dataTable.display tbody tr.odd > .sorting_2,
  table.dataTable.order-column.stripe tbody tr.odd > .sorting_2 {
    background-color: #f3f3f3; }
  table.dataTable.display tbody tr.odd > .sorting_3,
  table.dataTable.order-column.stripe tbody tr.odd > .sorting_3 {
    background-color: whitesmoke; }
  table.dataTable.display tbody tr.odd.selected > .sorting_1,
  table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
    background-color: #a6b4cd; }
  table.dataTable.display tbody tr.odd.selected > .sorting_2,
  table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2 {
    background-color: #a8b5cf; }
  table.dataTable.display tbody tr.odd.selected > .sorting_3,
  table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3 {
    background-color: #a9b7d1; }
  table.dataTable.display tbody tr.even > .sorting_1,
  table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
    background-color: #fafafa; }
  table.dataTable.display tbody tr.even > .sorting_2,
  table.dataTable.order-column.stripe tbody tr.even > .sorting_2 {
    background-color: #fcfcfc; }
  table.dataTable.display tbody tr.even > .sorting_3,
  table.dataTable.order-column.stripe tbody tr.even > .sorting_3 {
    background-color: #fefefe; }
  table.dataTable.display tbody tr.even.selected > .sorting_1,
  table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 {
    background-color: #acbad5; }
  table.dataTable.display tbody tr.even.selected > .sorting_2,
  table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2 {
    background-color: #aebcd6; }
  table.dataTable.display tbody tr.even.selected > .sorting_3,
  table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3 {
    background-color: #afbdd8; }
  table.dataTable.display tbody tr:hover > .sorting_1,
  table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
    background-color: #eaeaea; }
  table.dataTable.display tbody tr:hover > .sorting_2,
  table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
    background-color: #ececec; }
  table.dataTable.display tbody tr:hover > .sorting_3,
  table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
    background-color: #efefef; }
  table.dataTable.display tbody tr:hover.selected > .sorting_1,
  table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
    background-color: #a2aec7; }
  table.dataTable.display tbody tr:hover.selected > .sorting_2,
  table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
    background-color: #a3b0c9; }
  table.dataTable.display tbody tr:hover.selected > .sorting_3,
  table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
    background-color: #a5b2cb; }
  table.dataTable.no-footer {
    border-bottom: 1px solid #111; }
  table.dataTable.nowrap th, table.dataTable.nowrap td {
    white-space: nowrap; }
  table.dataTable.compact thead th,
  table.dataTable.compact thead td {
    padding: 4px 17px 4px 4px; }
  table.dataTable.compact tfoot th,
  table.dataTable.compact tfoot td {
    padding: 4px; }
  table.dataTable.compact tbody th,
  table.dataTable.compact tbody td {
    padding: 4px; }
  table.dataTable th.dt-left,
  table.dataTable td.dt-left {
    text-align: left; }
  table.dataTable th.dt-center,
  table.dataTable td.dt-center,
  table.dataTable td.dataTables_empty {
    text-align: center; }
  table.dataTable th.dt-right,
  table.dataTable td.dt-right {
    text-align: right; }
  table.dataTable th.dt-justify,
  table.dataTable td.dt-justify {
    text-align: justify; }
  table.dataTable th.dt-nowrap,
  table.dataTable td.dt-nowrap {
    white-space: nowrap; }
  table.dataTable thead th.dt-head-left,
  table.dataTable thead td.dt-head-left,
  table.dataTable tfoot th.dt-head-left,
  table.dataTable tfoot td.dt-head-left {
    text-align: left; }
  table.dataTable thead th.dt-head-center,
  table.dataTable thead td.dt-head-center,
  table.dataTable tfoot th.dt-head-center,
  table.dataTable tfoot td.dt-head-center {
    text-align: center; }
  table.dataTable thead th.dt-head-right,
  table.dataTable thead td.dt-head-right,
  table.dataTable tfoot th.dt-head-right,
  table.dataTable tfoot td.dt-head-right {
    text-align: right; }
  table.dataTable thead th.dt-head-justify,
  table.dataTable thead td.dt-head-justify,
  table.dataTable tfoot th.dt-head-justify,
  table.dataTable tfoot td.dt-head-justify {
    text-align: justify; }
  table.dataTable thead th.dt-head-nowrap,
  table.dataTable thead td.dt-head-nowrap,
  table.dataTable tfoot th.dt-head-nowrap,
  table.dataTable tfoot td.dt-head-nowrap {
    white-space: nowrap; }
  table.dataTable tbody th.dt-body-left,
  table.dataTable tbody td.dt-body-left {
    text-align: left; }
  table.dataTable tbody th.dt-body-center,
  table.dataTable tbody td.dt-body-center {
    text-align: center; }
  table.dataTable tbody th.dt-body-right,
  table.dataTable tbody td.dt-body-right {
    text-align: right; }
  table.dataTable tbody th.dt-body-justify,
  table.dataTable tbody td.dt-body-justify {
    text-align: justify; }
  table.dataTable tbody th.dt-body-nowrap,
  table.dataTable tbody td.dt-body-nowrap {
    white-space: nowrap; }

table.dataTable,
table.dataTable th,
table.dataTable td {
  box-sizing: content-box; }

/*
 * Control feature layout
 */
.dataTables_wrapper {
  position: relative;
  clear: both;
  *zoom: 1;
  zoom: 1; }
  .dataTables_wrapper .dataTables_length {
    float: left; }
  .dataTables_wrapper .dataTables_filter {
    float: right;
    text-align: right; }
    .dataTables_wrapper .dataTables_filter input {
      margin-left: 0.5em; }
  .dataTables_wrapper .dataTables_info {
    clear: both;
    float: left;
    padding-top: 0.755em; }
  .dataTables_wrapper .dataTables_paginate {
    float: right;
    text-align: right;
    padding-top: 0.25em; }
    .dataTables_wrapper .dataTables_paginate .paginate_button {
      box-sizing: border-box;
      display: inline-block;
      min-width: 1.5em;
      padding: 0.5em 1em;
      margin-left: 2px;
      text-align: center;
      text-decoration: none !important;
      cursor: pointer;
      *cursor: hand;
      color: #333 !important;
      border: 1px solid transparent;
      border-radius: 2px; }
      .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
        color: #333 !important;
        border: 1px solid #979797;
        background-color: white;
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(100%, #dcdcdc));
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, white 0%, #dcdcdc 100%);
        /* Chrome10+,Safari5.1+ */
        background: -moz-linear-gradient(top, white 0%, #dcdcdc 100%);
        /* FF3.6+ */
        background: -ms-linear-gradient(top, white 0%, #dcdcdc 100%);
        /* IE10+ */
        background: -o-linear-gradient(top, white 0%, #dcdcdc 100%);
        /* Opera 11.10+ */
        background: linear-gradient(to bottom, white 0%, #dcdcdc 100%);
        /* W3C */ }
      .dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
        cursor: default;
        color: #666 !important;
        border: 1px solid transparent;
        background: transparent;
        box-shadow: none; }
      .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
        color: white !important;
        border: 1px solid #111;
        background-color: #585858;
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
        /* Chrome10+,Safari5.1+ */
        background: -moz-linear-gradient(top, #585858 0%, #111 100%);
        /* FF3.6+ */
        background: -ms-linear-gradient(top, #585858 0%, #111 100%);
        /* IE10+ */
        background: -o-linear-gradient(top, #585858 0%, #111 100%);
        /* Opera 11.10+ */
        background: linear-gradient(to bottom, #585858 0%, #111 100%);
        /* W3C */ }
      .dataTables_wrapper .dataTables_paginate .paginate_button:active {
        outline: none;
        background-color: #2b2b2b;
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2b2b2b), color-stop(100%, #0c0c0c));
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
        /* Chrome10+,Safari5.1+ */
        background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
        /* FF3.6+ */
        background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
        /* IE10+ */
        background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
        /* Opera 11.10+ */
        background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
        /* W3C */
        box-shadow: inset 0 0 3px #111; }
    .dataTables_wrapper .dataTables_paginate .ellipsis {
      padding: 0 1em; }
  .dataTables_wrapper .dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 40px;
    margin-left: -50%;
    margin-top: -25px;
    padding-top: 20px;
    text-align: center;
    font-size: 1.2em;
    background-color: white;
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%); }
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter,
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_processing,
  .dataTables_wrapper .dataTables_paginate {
    color: #333; }
  .dataTables_wrapper .dataTables_scroll {
    clear: both; }
    .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
      *margin-top: -1px;
      -webkit-overflow-scrolling: touch; }
      .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td {
        vertical-align: middle; }
      .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th > div.dataTables_sizing,
      .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th > div.dataTables_sizing,
      .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td > div.dataTables_sizing {
        height: 0;
        overflow: hidden;
        margin: 0 !important;
        padding: 0 !important; }
  .dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: 1px solid #111; }
  .dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable,
  .dataTables_wrapper.no-footer div.dataTables_scrollBody > table {
    border-bottom: none; }
  .dataTables_wrapper:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0; }

@media screen and (max-width: 767px) {
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: center; }
  .dataTables_wrapper .dataTables_paginate {
    margin-top: 0.5em; } }

@media screen and (max-width: 640px) {
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter {
    float: none;
    text-align: center; }
  .dataTables_wrapper .dataTables_filter {
    margin-top: 0.5em; } }
